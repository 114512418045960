.outer {
  flex: 1;
  background-color: #f6fcff;
  padding: 24px;
}
.mainView {
  height: 100vh;
  display: flex;
}
.outer.admin-module-content{
  max-width: calc(100% - 318px);
}