@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  background-size: cover;
}

body * {
  font-family: "Lato", sans-serif !important;
}

.cursor-pointer {
  cursor: pointer;
}

.p-relative {
  position: relative;
}

.d-flex {
  display: flex;
  padding: 20px;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-end-important {
  justify-content: end !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.padding {
  padding: auto;
}

.d-none {
  display: none;
}

.loaderIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dialog-content-form {
  padding: 22px 30px !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  align-self: start;
}

.planId {
  background-color: #287ead;
  border-radius: 4px;
  color: #fff;
  padding: 2px 7px;
  margin-left: 8px !important;
}

.planLabel {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #515c6399;
}

.planText {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #515c63;
}

.rewardRangeCont {
  box-shadow: 3px 3px 10px 0px #0000001a;
  border-radius: 12px;
  margin-top: 12px !important;
}

.planSubText {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #515c63;
}
