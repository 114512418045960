.sidebar {
  width: 238px;
  background-color: rgba(6, 0, 44, 1);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list {
  color: white;
  text-align: left;
}

.sidebar-header {
  padding: 20px;
  text-align: left;
}

.sidebar-header img {
  width: 100px;
  height: auto;
}

nav {
  display: flex;
  flex-direction: column;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
}

nav a.active {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-info {
  padding: 20px;
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%;
  margin-right: 10px;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
}
.sidebar-nav-link {
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  padding: 8px 16px;
  background: #06002c;
  width: 100%;
}
a.sidebar-nav-link.active {
  background: #287ead;
}
.admin-nav-sidebar{
  width: 240px;
}