.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
  outline: none;
}

.switch input {
  visibility: hidden;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b3e613d;
  box-shadow: 0px 4px 16px 0px #1b3e610f;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(6, 0, 44, 1);
}

input:focus + .slider {
  box-shadow: 0 0 1px #1b3e61;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.clr-white {
  color: #ffffff;
  font-size: 12px;
  font-family: "Segoe UI Bold";
  text-transform: uppercase;
}
