@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  /* background: url('path-to-your-background-image.png') no-repeat center center fixed; */
  background-size: cover;
}

.container {
  background-image: url("../assets/svg/background.jpg");
  font-weight: 400;
  font-family: "Lato";
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-header {
  color: #ccc;
  font-size: 14.5px;
}

.image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-box {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.12);
  padding: 25px 20px 30px 20px;
  color: white;
  border-radius: 10px;
  width: 360px;
  /* padding-bottom: 30px; */
  margin-top: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-button {
  width: 100%;
  padding: 15px 0px 15px;
  margin-top: 20px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-family: "Lato";
}

* .login-button:hover {
  background-color: "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)";
}

.signIn {
  font-size: 28;
  padding-bottom: 2;
  font-family: "Overpass ";
}
