.main {
  background-color: rgba(246, 252, 255, 1);
}

.game-detail-container {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 16px rgba(69, 90, 100, 0.6);
  border: 1px solid rgba(40, 126, 173, 0.12);
  border-radius: 12px;
}

.form-field {
  margin-bottom: 15px;
  font-family: "Lato";
}

.form-template {
  background-color: #287ead;
  color: #ffffff;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.required-field label:after {
  content: "*";
  color: red;
}

.image-section {
  width: 100%;
  height: 132px; 

}

.image-upload {
  position: relative;
  width: 100%;
}

.floating-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  font-size: 14px;
  color: rgba(81, 92, 99, 0.6);
  z-index: 1;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 128px;
  width: 100%;
  border: 2px dashed rgba(40, 126, 173, 0.12);
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(40, 126, 173, 0.02);
  position: relative;
}

.upload-icon {
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
}

.browse-btn {
  font-size: 12px;
  border: 1px solid rgba(40, 126, 173, 0.1);
  border-radius: 8px;
  color: rgba(40, 126, 173, 0.8);
  background-color: rgba(40, 126, 173, 0.1);
  padding: 5px;
  margin-left: 4px;
}

.uploaded-img {
  max-width: 100%;
  max-height: 114px;
  padding: 8px 0 0 0;
  border-radius: 8px;
  width: 100%;
  border: 1px dashed rgba(40, 126, 173, 0.1);
  object-fit: contain;
}

.submit-button {
  align-self: flex-end;
  padding: 10px;
  font-size: 18px;
}

.typo {
  margin: 10px;
}

.leaderboard {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.leaderboard-header,
.leaderboard-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.leaderboard-row:nth-child(even) {
  background-color: #fff;
}

.leaderboard-header {
  font-weight: bold;
}

.flex-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

body .page-title {
  margin-bottom: 21px;
}

body .game-detail-container .select-template label {
  color: #ffffff !important;
}

body .game-detail-container .select-template label.MuiFormLabel-filled,
body .game-detail-container .select-template label.MuiInputLabel-shrink {
  color: rgba(81, 92, 99, 0.6) !important;
  font-weight: 600;
  top: -9px;
  left: -13px;
}
