.all-games-view {
  flex: 1;
}

.all-games-inner-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.table-loader{
  display: flex;
  min-height: 70vh;
  align-items: center;
  justify-content: center;
}

.header-filter {
  min-width: 400px;
}

.filter-label-text {
  min-width: 53px;
}

.search-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.even-row {
  background-color: #de0a0a;
}

.odd-row {
  background-color: #401c1c;
}

.btn {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Lato";
}

.MuiDataGrid-root {
  background-color: white;
  border-radius: 10px;
  border: none;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

.custom-text-field .MuiOutlinedInput-root {
  border-width: 2px;
  border-color: rgba(40, 126, 173, 0.12) !important;
}

.custom-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 0, 0) !important;
}

.custom-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgb(133, 130, 130) !important;
}

.custom-text-field .MuiInputBase-input::placeholder {
  color: gray !important;
}

.cursor-pointer {
  margin-right: 10px;
}

.action-icon {
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
}

.action-icon:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

.action-icon:active {
  background-color: #e0e0e0;
  transform: scale(1);
}
